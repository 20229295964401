<template>
	<div>
		<pui-datatable :modelName="model" :showDeleteBtn="true" :actions="actions" :modelColumnDefs="columnDefs"></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import SeverityActions from './PmSeverityActions';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'pmseveritygrid',
	data() {
		return {
			model: 'pmseverity',
			actions: SeverityActions.actions,
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				color: {
					createdCell: (td, cellData, rowData) => {
						return (td.innerHTML = `<label class="fw-500" style="border-radius:4px;padding:2px 4px;background-color:${rowData.color + '99'};color:black">${cellData}</label>`);
					}
				},
				iconroute: {
					createdCell: (td, cellData) => {
						td.style.padding = '6px 0px 0px 20px';
						td.style.width = '3rem';
						return (td.innerHTML = '<img src="' + this.iconPath(cellData) + '" style="max-width:1.5rem;max-height:1.5rem"></img>');
					}
				}
			}
		};
	},
	methods: {
		iconPath(route) {
			var appProperties = this.$store.getters.getApplication;
			return appProperties.urlbase + route;
		}
	}
};
</script>

<style lang="postcss" scoped></style>
